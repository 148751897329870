import React from 'react'
import {useState, useEffect} from 'react'
import styled from "styled-components"
import { Link } from 'gatsby'
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import { pc, sp } from '../styles/media';
import PropertyConverter from "../components/PropertyConverter";
import useMedia from 'use-media';
import ContractedMark from './ContractedMark';


const PropertiesListContainer = styled.div`
`
const PropertiesListUnit = styled.div`
    ${sp`
        padding: 16px;
    `}
    ${pc`
        padding: 28px;
    `}
    position: relative;
    display: flex;
    width: 100%;
    margin-bottom: 32px;
    box-shadow: 0 2px 6px 0 rgb(0 0 0 / 12%);
    background: linear-gradient(to bottom, #fafafa, #fdfdfd);
    background: linear-gradient(rgba(226,226,226),white);
`
const PropertiesListImageCell = styled.div`
    ${sp`
    width: 88px;
    `}
    ${pc`
    width: 168px;
    `}
    vertical-align: top;
`
const PropertiesListSummaryCell = styled.div`
    ${sp`
    font-size: 13px;
    `}
    flex: 1;
    position: relative;
    padding-left: 19px;
    vertical-align: top;
`
const PropertiesListSummaryTitle = styled.div`
    position: relative;
    margin-bottom: 8px;
    ${sp`
        padding-right: 0px;
        & h2{
            font-size: 16px;
            font-weight: 600;
            line-height: 1.3;
        }
    `}
    ${pc`
        padding-right: 176px;
        & h2{
            font-size: 26px;
            font-weight: 600;
            line-height: 1.3;
        }
    `}
`
const PropertiesListSummaryDetail = styled.div`
    display: table;
    width: 100%;

    & section{
        display: table-cell;
        padding-right: 32px;
        font-size: 14px;
        vertical-align: top;

        & table {
            border-collapse: collapse;
            & th {
                ${sp`
                padding-right: 0px;
                font-size: 12px;
                font-weight: 600;
                width: 53px;
                height: 21px;
                line-height: 19.5px;
                `}
                ${pc`
                width: 68px;
                text-align: center;
                font-weight: 600;
                vertical-align: top;
                padding: 5px 0px 5px 10px;
                `}
            }
            & td {
                ${sp`
                height: 21px;
                line-height: 19px;
                font-size: 12px;
                `}
                ${pc`
                vertical-align: top;
                padding: 5px 0px 5px 10px;
                `}
                p {
                    padding-bottom: 5px;
                }
            }
        }
    }
`

const MoreBottun = styled.p`
    width: 100%;
    text-align: right;
    color: #59C9AB;
    font-weight: bold;
    cursor: pointer;
`

const GatsbyImagePcStyle = {
    "height": "150px"
}

const SectionSpStyle = {
    "padding-right": "10px"
}

export default function PropertiesList({SearchId, SearchData}) {
    const [nextCount, setNextCount] = useState(0);
    const [listData, setListData] = useState([]);
    const [allData, setAllData] = useState([]);
    useEffect(() => {
        let _listData = SearchData?.all?.listPropertiesSortByUpdatedAt?.items || []
        if(SearchId === "1"){
            _listData = SearchData?.owned?.listPropertiesSortByUpdatedAt?.items || []
        }else if(SearchId === "2"){
            _listData = SearchData?.notOwned?.listPropertiesSortByUpdatedAt?.items || []
        }
        setAllData(_listData)
        setListData(_listData.slice(nextCount, nextCount+5))
        setNextCount(nextCount+5)
    }, [])
    useEffect(() => {
        let _listData = SearchData?.all?.listPropertiesSortByUpdatedAt?.items || []
        if(SearchId === "1"){
            _listData = SearchData?.owned?.listPropertiesSortByUpdatedAt?.items || []
        }else if(SearchId === "2"){
            _listData = SearchData?.notOwned?.listPropertiesSortByUpdatedAt?.items || []
        }
        setAllData(_listData)
        setListData(_listData.slice(0, 5))
        setNextCount(5)
    }, [SearchId])

    const isPc = useMedia({minWidth: '560px'});
    const width_ = isPc ? "168px" : "88px";
    const height_ = isPc ? "150px" : "150px";
    const fontSize_ = isPc ? "28px" : "1.4em";

    const moreProperty = (nextCount) => {
        if(nextCount!==null){
            let _listData = [...listData, ...allData.slice(nextCount, nextCount+5)]
            setListData(_listData)
            setNextCount(nextCount+5)
        }
    }
    
    return (
        <PropertiesListContainer>
            {listData.map((item) => {
                let path = "/property/" + item.id;
                const Thumbnail = getImage(item.pathPropertyImages[0])
                const convertedItem = new PropertyConverter(item).convert()

                return(
                    <a href={path} key={convertedItem.id} target="_blank">
                        <PropertiesListUnit key={convertedItem.id}>
                            {item.iscontracted ? <ContractedMark width={width_} height={height_} fontSize={fontSize_} /> : null}
                            <PropertiesListImageCell>
                                <GatsbyImage style={GatsbyImagePcStyle} className={convertedItem.title} image={Thumbnail} />
                            </PropertiesListImageCell>
                            <PropertiesListSummaryCell>
                                <PropertiesListSummaryTitle>
                                    <h2>{convertedItem.title}</h2>
                                </PropertiesListSummaryTitle>
                                <PropertiesListSummaryDetail>
                                    <section style={isPc ? null : SectionSpStyle}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th>所在地</th><td>{convertedItem.prefecture}{convertedItem.city}{convertedItem.address}</td>
                                                </tr>
                                                <tr>
                                                    <th>賃料</th><td>{convertedItem.rent} 円</td>
                                                </tr>
                                                <tr>
                                                    {/* 合計: 1000㎡/302.50坪（1F: 500㎡/150.00坪, 2F: 300㎡/90.00坪, 3F: 200㎡/60.00坪） */}
                                                    {/* <th>建物面積</th><td>合計: {item.buildingAreaTotal}（テスト: {item.buildingAreaTotal}）</td> */}
                                                    <th>建物面積</th><td>合計：{convertedItem.buildingAreaTotal}㎡/{convertedItem.buildingAreaTotalTsubo}坪　{
                                                        (convertedItem?.buildingAreaEach?.length > 0) ? "(" : ""
                                                    }{
                                                        convertedItem.buildingAreaEach.map((area, i) => {
                                                        let eol_ = convertedItem?.buildingAreaEach?.length===i+1 ? "" : ", "
                                                        return (
                                                            // i+1 + "F：" + area + "㎡/" + convertedItem.buildingAreaEachTsubo[i] + "坪" + eol_ 
                                                            i+1 + "F：" + area + eol_ 
                                                        )
                                                        })
                                                    }{
                                                        (convertedItem?.buildingAreaEach?.length > 0) ? "）" : ""
                                                    }</td>
                                                </tr>
                                                <tr>
                                                    <th>備考</th><td>
                                                    <div>{convertedItem?.remarks?.split("\n").map((line) => {
                                                        return (
                                                        <p>{line}</p>
                                                        )
                                                    })}</div>
                                                   </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </section>
                                </PropertiesListSummaryDetail>
                            </PropertiesListSummaryCell>
                        </PropertiesListUnit>
                     </a>
                )
            })}
        {(nextCount >= allData.length) ? null :
            <MoreBottun onClick={() => moreProperty(nextCount)}>もっと見る</MoreBottun>
        }
        </PropertiesListContainer>
    )
}
