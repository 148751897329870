import React from 'react'
import Layout from "../components/Layout"
import PropertiesList from '../components/PropertiesList'
import {graphql} from "gatsby"
import styled from "styled-components"
import { Helmet } from "react-helmet"
import { useEffect, useState } from "react"
import { pc, sp, tab } from '../styles/media';


const PropertiesTitle = styled.h1`
    font-size: 24px;
    font-weight: bold;
    padding: 10px 0px;
    margin: 20px 0px;
    margin-top: 150px;
    border-bottom: solid 1px #cbd0d5;
    ${sp`
    margin-top: 50px;
    `}
    ${pc`
    margin-top: 150px;
    `}
`

export default function Properties({location, data}) {
    const searchId = location?.state?.SearchId || "3"
    const searchData = data

    const [title, setTitle] = useState("物件一覧")
    useEffect(() => {
        if(searchId === "1"){
            setTitle("募集中物件一覧")
        }else if(searchId === "2"){
            setTitle("成約済物件一覧")
        }else{
            setTitle("全物件一覧")
        }
    }, [searchId])
    return (
        <Layout layoutType="Properties">
            <Helmet>
                <title>【ビオエスト株式会社】｜関西エリアの倉庫・工場・土地物件</title>
                <meta name="description" content="倉庫・工場・土地等の運用についてお悩みなら、【ビオエスト株式会社】にご相談ください。
        主に大阪・和歌山・奈良・兵庫エリアにて、賃貸・売買・管理・物件調査、査定を通じて適切な運用方法をご一緒に検討いたします。" />
            </Helmet>
            <PropertiesTitle>{title}</PropertiesTitle>
            <PropertiesList SearchId={searchId} SearchData={searchData} />
        </Layout>
    )
}

export const query = graphql`
query SearchPropertiesQuery {
    owned: Data {
        listPropertiesSortByUpdatedAt(
            sortDirection: DESC
            type: PROPERTY
            filter: {iscontracted: {eq: false}, _deleted: {ne: true}}
          ) {
            items {
                prefecture
                city
                address
                buildingAreaTotal
                buildingAreaEach
                iscontracted
                id
                title
                rent
                image01
                image02
                image03
                image04
                image05
                drawing01
                drawing02
                drawing03
                drawing04
                drawing05
                remarks
                pathPropertyImages {
                    childImageSharp {
                        gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
                    }
                }
                pathDrawingImages {
                    childImageSharp {
                        gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
                    }
                }
            }
        }
    }
    notOwned: Data {
        listPropertiesSortByUpdatedAt(
            sortDirection: DESC
            type: PROPERTY
            filter: {iscontracted: {eq: true}, _deleted: {ne: true}}
          ) {
            items {
                prefecture
                city
                address
                buildingAreaTotal
                buildingAreaEach
                iscontracted
                id
                title
                rent
                remarks
                image01
                image02
                image03
                image04
                image05
                drawing01
                drawing02
                drawing03
                drawing04
                drawing05
                pathPropertyImages {
                    childImageSharp {
                        gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
                    }
                }
                pathDrawingImages {
                    childImageSharp {
                        gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
                    }
                }
            }
        }
    }
    all: Data {
        listPropertiesSortByUpdatedAt(
            filter: {_deleted: {ne: true}}
            sortDirection: DESC
            type: PROPERTY
          ) {
            items {
                prefecture
                city
                address
                buildingAreaTotal
                buildingAreaEach
                iscontracted
                id
                title
                rent
                remarks
                image01
                image02
                image03
                image04
                image05
                drawing01
                drawing02
                drawing03
                drawing04
                drawing05
                pathPropertyImages {
                    childImageSharp {
                        gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
                    }
                }
                pathDrawingImages {
                    childImageSharp {
                        gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
                    }
                }
            }
        }
    }
}
`
